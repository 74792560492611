import {createInertiaApp} from '@inertiajs/react'
import {createRoot, hydrateRoot} from 'react-dom/client'
import React, {ReactNode} from "react";
import Layout from "@/Components/Layout";
import './app.scss';
import './app.mobile.scss';
import MyApp from '@/Components/App';
import './i18n';

document.addEventListener('inertia:error', (e) => {
    console.error('inertia:error', e)
    e.preventDefault()
})

createInertiaApp({
    progress: {
        color: '#015dca',
    },
    resolve: async name => {
        const pages = import.meta.glob('./Pages/**/*.tsx')
        const page = await pages[`./Pages/${name}.tsx`]()
        //@ts-ignore
        page.default.layout = page.default.layout || ((page: ReactNode) => <Layout children={page}/>)

        return page
    },
    setup({el, App, props}) {
        const app = <MyApp>
            <App {...props} />
        </MyApp>

        if (import.meta.env.DEV) {
            createRoot(el).render(app)
        } else {
            // ssr 渲染请使用以下代码
            hydrateRoot(el, app)
        }
    },
})
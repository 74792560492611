function isObject(item: any): item is object {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepMerge(target: Record<string, any>, ...sources: Record<string, any>[]) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return deepMerge(target, ...sources);
}

export function excludeObjectKeys<T extends object, K extends keyof T>(obj: T, keysToExclude: K[]): Omit<T, K> {
    const result = {...obj};
    keysToExclude.forEach(key => {
        delete result[key];
    });
    return result as Omit<T, K>;
}
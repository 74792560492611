import {useEffect, useState} from "react";
import {StyleProvider} from '@ant-design/cssinjs';
import DeviceContext from "@/Components/DeviceContext";

export default function ({children}: {
    children: React.ReactNode
}) {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (import.meta.env.SSR) {
            return
        }

        const onResize = () => {
            if (screen.orientation.angle) {
                return
            }
            setIsMobile(getWidth() < 768)
        }
        window.addEventListener('resize', onResize)
        onResize()
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    useEffect(() => {
        const div = document.querySelector('#mobile-loading-mask')
        if (!div) {
            return
        }
        setTimeout(() => {
            div.remove()
        }, 1000)
        setTimeout(() => {
            div.classList.add('hidden')
        }, 300)

    }, []);

    const getWidth = () => {
        return document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth
    }

    useEffect(() => {
        const html = document.getElementsByTagName("html")[0];

        function getRem(pwidth: number, prem: number) {
            const oWidth = document.body.clientWidth || document.documentElement.clientWidth;
            html.style.fontSize = oWidth / pwidth * prem + "px";
        }

        const listener = function () {
            getRem(750, 100)
        };

        if (isMobile) {
            getRem(750, 100)
            window.addEventListener('resize', listener);

            return () => {
                window.removeEventListener('resize', listener);
            }
        } else {
            window.removeEventListener('resize', listener);
            html.style.fontSize = '';
        }
    }, [isMobile])

    return <>
        <DeviceContext.Provider value={{
            isMobile: isMobile,
        }}>
            <StyleProvider hashPriority="high">
                <div className={isMobile ? 'device-mobile' : 'device-default'}>
                    {children}
                </div>
            </StyleProvider>
        </DeviceContext.Provider>
    </>
}
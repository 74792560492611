import {PropsWithChildren} from "react";
import {useDeviceDetector} from "@/Hooks/useDeviceDetector";
import {router} from "@inertiajs/react";

export default function (props: PropsWithChildren<Record<string, any>>) {
    const {isMobile} = useDeviceDetector()

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (props.href === 'javascript:;') {
            return;
        }

        if (new URL(props.href, window.location.href).host !== window.location.host) {
            window.open(props.href)
            return
        }

        if (isMobile) {
            router.visit(props.href)

            return
        }

        if (props.target === '_blank') {
            window.open(props.href)
            return
        }
        router.visit(props.href)
    }

    return <a {...props} onClick={onClick}>{props.children}</a>
}
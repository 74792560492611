import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';

export default function (props: {
    gb?: string,
    big5?: string,
    className?: string,
}) {
    const [isBig5, setIsBig5] = useState(false)
    const {i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(isBig5 ? 'zh-HK' : 'zh-CN')
    }, [isBig5])


    useEffect(() => {
        const t = sessionStorage.getItem('big5-lang')
        if (t) {
            setIsBig5(!!t)
        }
    }, []);

    const onClick = (e: any) => {
        e.preventDefault()
        sessionStorage.setItem('big5-lang', !isBig5 ? '1' : '')
        setIsBig5(!isBig5)
    }

    return <>
        <a href="#" className={props.className} onClick={onClick}>
            <span>{!isBig5 ? props.big5 || '繁體中文' : props.gb || '简体中文'}</span>
        </a>
    </>
}
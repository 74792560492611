import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {simpleToTrad} from 'simptrad'

i18n
    .use(initReactI18next)
    .use({
        type: 'postProcessor',
        name: 'convertChinese',
        process: function (value: string, key: string, options: {
            lng: string,
            i18nResolved: {
                usedLng: string
            }
        }) {
            if (options.i18nResolved.usedLng === 'zh-HK') {
                return simpleToTrad(value)
            }
            return value
        }
    })
    .init({
        lng: 'zh-CN', // 默认语言
        postProcessPassResolved: true,
        postProcess: ['convertChinese'], // 使用后处理器
        supportedLngs: ['zh-CN', 'zh-HK'],
        react: {}
    });


export default i18n;

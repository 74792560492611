import {lazy, LazyExoticComponent, Suspense, useEffect, useState} from "react";

export default function (props: {
    fallback?: React.ReactNode,
    loader: () => Promise<any>,
} & Record<string, any>) {
    const {loader} = props;
    const [Component, setComponent] = useState<LazyExoticComponent<any> | null>(null);

    useEffect(() => {
        loader().then(Component => {
            setComponent(lazy(loader));
        });
    }, []);

    return <Suspense fallback={props.fallback}>
        {/* @ts-ignore */}
        {Component && <Component {...{
            ...props,
            loader: undefined,
            fallback: undefined,
        }}/>}
    </Suspense>
}
import {useContext, useEffect, useMemo, useState} from "react";
import DeviceContext from "@/Components/DeviceContext";

export function useDeviceDetector(options?: {
    mobileStyles?: CSSModuleClasses,
    defaultStyles?: CSSModuleClasses,
}) {
    const context = useContext(DeviceContext)
    const isMobile = useMemo(() => {
        return context?.isMobile || false
    }, [context?.isMobile])

    const [styles, setStyles] = useState<CSSModuleClasses>(options?.defaultStyles || {})

    useEffect(() => {
        if (isMobile) {
            setStyles(options?.mobileStyles as CSSModuleClasses)
        } else {
            setStyles(options?.defaultStyles as CSSModuleClasses)
        }
    }, [isMobile])

    return {isMobile, styles}
}

import {useEffect, useState} from "react";
import {GlobalEvent} from "@inertiajs/core/types/types";
import {parse} from "qs";

export default function useQuery() {
    const getQuery = (url: string) => {
        return parse(url.replace(/^[^?]*\?/, ''))
    }

    let initQuery = {}
    if (!import.meta.env.SSR) {
        initQuery = getQuery(window.location.href)
    }

    const [query, setQuery] = useState<Record<string, any>>(initQuery)

    useEffect(() => {

        const listener = (e: GlobalEvent<'navigate'>) => {
            setQuery(getQuery(e.detail.page.url))
        }
        document.addEventListener('inertia:navigate', listener)
        return () => {
            document.removeEventListener('inertia:navigate', listener)
        }
    }, []);

    return query
}
import {ReactNode} from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import {LayoutContext} from "@/Components/LayoutContext";
import {Head} from "@inertiajs/react";
import {useTranslation} from "react-i18next";
import ReturnTop from "@/Components/Common/ReturnTop";
import WechatShare from "@/Components/Common/WechatShare";
import {route} from "@/route";
import useLayoutProps from "@/Hooks/useLayoutProps";

export type LayoutProps = {
    metaTitle: string,
    siteTitle: string,
    defaultThumb: string,
    donateMonthLink: string,

    header: {
        logo: string,
        activeNav: string,
        nav: {
            title: string,
            link: string,
            children?: {
                title: string,
                link: string
            }[]
        }[]
    },

    footer: {
        copyright: string;
        wechatQrcode: string;
        bgImg: string,
        contactUs: string,
        contactUs_EN: string,
        monthDonate: {
            title: string,
            title_En: string,
            desc: string,
            desc_En: string,
            qrcode: string,
        }
        follows: {
            title: string,
            url: string,
            icon: string,
        }[]
    }
}

export default function ({children}: {
    children: ReactNode
}) {
    const {t} = useTranslation()
    const layoutProps = useLayoutProps()

    return <>
        <Head>
            <title>{t(layoutProps.metaTitle ? layoutProps.metaTitle + '-' + layoutProps.siteTitle : layoutProps.siteTitle)}</title>
            <link ref={'icon'} href={route('/favicon.ico')}/>
            <meta name="keywords" content="满天星公益︱专注于乡村儿童阅读推广的公益机构"/>
            <meta name="description"
                  content="致力于提高乡村儿童的阅读品质，通过探索乡村阅读整体解决方案，推动乡村儿童阅读服务的专业化和标准化，让每一个孩子都能发现出色的自我，共同创造丰富多彩的世界！"/>
        </Head>
        <LayoutContext.Provider value={{
            props: layoutProps
        }}>
            <Header></Header>
            <div className={'page-content'}>
                {children}
            </div>
            <ReturnTop/>
            <Footer></Footer>
            <WechatShare/>
        </LayoutContext.Provider>
    </>
}
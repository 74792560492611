import {useEffect, useState} from "react";
import {usePage} from "@inertiajs/react";
import {LayoutProps} from "@/Components/Layout";

export default function () {

    const props = usePage<{
        layoutProps: LayoutProps
    }>().props
    const [layoutProps, setLayoutProps] = useState(props.layoutProps as LayoutProps)

    useEffect(() => {
        const listener = (e: CustomEvent) => {
            console.log(e.detail.page.props)
            const props = {
                ...layoutProps,
                metaTitle: e.detail.page.props?.layoutProps?.metaTitle || '',
            }
            props.header.activeNav = e.detail.page.props?.layoutProps?.header?.activeNav || ''

            setLayoutProps(props)
        }
        document.addEventListener('inertia:navigate', listener)

        return () => {
            document.removeEventListener('inertia:navigate', listener)
        }
    }, []);

    return layoutProps
}
import defaultStyle from './Footer.module.scss'
import {Link} from "@inertiajs/react";
import {route} from "@/route";
import {Trans, useTranslation} from "react-i18next";
import {useDeviceDetector} from "@/Hooks/useDeviceDetector";
import mobileStyle from './Footer.mobile.module.scss'
import {useContext} from "react";
import {LayoutContext} from "@/Components/LayoutContext";
import ImageCrop from "@/Components/Common/ImageCrop";
import ArticleContent from "@/Components/ArticleContent";
import MLink from "@/Components/Common/MLink";

export default function () {
    const {t} = useTranslation();
    const {styles} = useDeviceDetector({
        defaultStyles: defaultStyle,
        mobileStyles: mobileStyle,
    })

    const layoutContext = useContext(LayoutContext)

    return <>
        <div className={styles.footer}>
            <div className={styles.monthDonate}>
                <div className={styles.donateImg} style={{
                    backgroundImage: `url(${layoutContext.props.footer?.bgImg})`
                }}></div>
                <div className={styles.content}>
                    <div className={styles.right}>
                        <div className={styles.qrcode}>
                            <ImageCrop width={340} height={340} src={layoutContext.props.footer.monthDonate.qrcode}
                                       alt=""/>
                        </div>
                    </div>

                    <div className={styles.text}>
                        <div className={styles.title}>{t(layoutContext.props.footer.monthDonate.title)}</div>
                        <p>{t(layoutContext.props.footer.monthDonate.desc)}</p>
                    </div>
                </div>
            </div>
            <div className={styles.contact}>
                <div className={"container " + styles.content}>
                    <div className={styles.item + ' ' + styles.contactUs}>
                        <div className={styles.title}>{t('联系我们')}</div>
                        <div className={styles.body}>
                            <Trans>
                                {layoutContext.props.footer.contactUs?.split('\n').map((line, index) => {
                                    return <p key={index}>{line}</p>
                                })}
                            </Trans>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.item + ' ' + styles.focusUs}>
                            <div className={styles.title}>{t('关注我们')}</div>
                            <div className={styles.body}>
                                <div className={styles.methodList}>
                                    {layoutContext.props.footer.follows?.map(item => (
                                        <div key={item.title} className={styles.methodItem}>
                                            {item.url
                                                ?
                                                <MLink className={styles.methodItem} href={item.url}
                                                   target={"_blank"} title={t(item.title)}>
                                                    <ImageCrop toJpg={false} width={35} height={35} src={item.icon}
                                                               alt=""/>
                                                </MLink>
                                                :
                                                <div title={t(item.title)}>
                                                    <ImageCrop toJpg={false} width={35} height={35} src={item.icon}
                                                               alt=""/>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                <Link className={styles.apply}
                                      href={route('/Home/Coop/content')}>{t('合作伙伴登记')}</Link>
                            </div>
                        </div>
                        <div className={styles.item + ' ' + styles.qrcode}>
                            <div className={styles.title}>{t('微信公众号')}</div>
                            <div className={styles.body}>
                                <ImageCrop width={340} height={340} src={layoutContext.props.footer.wechatQrcode}
                                           alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>
                <div className={"container " + styles.content}>
                    <ArticleContent content={layoutContext.props.footer.copyright}></ArticleContent>
                </div>
            </div>
        </div>
    </>
}
import defaultStyles from './Header.module.scss'
import mobileStyles from './Header.mobile.module.scss'
import {Link, useForm} from "@inertiajs/react";
import {route} from "@/route";
import {useContext, useEffect, useMemo, useState} from "react";
import {LayoutContext} from "@/Components/LayoutContext";
import TranslateBig5 from "@/Components/Layout/TranslateBig5";
import {useTranslation} from "react-i18next";
import {useDeviceDetector} from "@/Hooks/useDeviceDetector";
import {Collapse} from "antd";
import {CollapsibleType} from "antd/es/collapse/CollapsePanel";
import ImageCrop from "@/Components/Common/ImageCrop";
import useQuery from "@/Hooks/useQuery";
import MLink from "@/Components/Common/MLink";

export default function () {
    const layoutContext = useContext(LayoutContext)
    const activeNav = useMemo(() => layoutContext.props.header?.activeNav.split('.'), [layoutContext.props.header?.activeNav])
    const {t} = useTranslation()
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuCollapseActive, setMenuCollapseActive] = useState([''])

    const {isMobile, styles} = useDeviceDetector({
        mobileStyles: mobileStyles,
        defaultStyles: defaultStyles
    })

    const query = useQuery()

    const onOpenMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        document.body.style.overflow = menuOpen ? 'hidden' : ''
    }, [menuOpen])

    useEffect(() => {
        form.setData('search', query?.search || '')
        form.setData('type', query?.type || 'news')
    }, [query]);

    const form = useForm({
        search: query?.search || '',
        type: query?.type || 'news',
    })

    const onSearch = () => {
        form.get(route('/Home/Search/index'), {
            preserveState: true,
        })
    }

    useEffect(() => {
        const listener = () => {
            setMenuOpen(false)
        }
        document.addEventListener('inertia:navigate', listener)
        return () => {
            document.removeEventListener('inertia:navigate', listener)
        }
    }, []);

    useEffect(() => {
        setMenuCollapseActive([activeNav[0] || ''])
    }, [activeNav]);

    const menuCollapseItems = [
        {
            key: 'home',
            label: <Link href={route('/')} className={'menu-link'}>{t('首页')}</Link>,
            showArrow: false,
            collapsible: 'disabled' as CollapsibleType,
        },
        ...layoutContext.props.header?.nav?.map(item => {
            return {
                key: item.title,
                label: <Link href={item.link} className={'menu-link'}>{t(item.title)}</Link>,
                collapsible: (item.children?.length ? 'icon' : 'disabled') as CollapsibleType,
                showArrow: !!item.children?.length,
                children: <>
                    {
                        item.children && <ul className={'nav-sub'}>
                            {
                                item.children?.map(subItem => {
                                    return <li key={subItem.title}
                                               className={(activeNav[1] || '') === subItem.title ? 'active' : ''}><Link
                                        href={subItem.link}>{t(subItem.title)}</Link></li>
                                })
                            }
                        </ul>
                    }
                </>

            }
        }),
    ]

    return <>
        {isMobile ? (
            <div className={styles.headerContainer}>
                <div className='header-wrap'>
                    <div className="container">
                        <Link className='logo' href={route('/')}>
                            <ImageCrop src={layoutContext.props.header.logo} srcSet={{
                                '1x': {
                                    height: 60,
                                },
                                '2x': {
                                    height: 120,
                                },
                                '1.5x': {
                                    height: 120,
                                }
                            }}/>
                        </Link>
                        <div className={'menu-open'} onClick={onOpenMenu}>
                            <span className={'icon-hamburger ' + (menuOpen ? 'open' : '')}></span>
                        </div>
                    </div>
                </div>

                <div className={'menu-wrap ' + (menuOpen ? 'open' : '')}>
                    <div className="container">
                        <div className={'trans-switch'}>
                            <Link className={'item'} href={route('/Home/IndexEn/index')}>EN</Link>
                            <TranslateBig5 className={'item'} gb={'简'} big5={'繁'}></TranslateBig5>
                        </div>

                        <div className='search-wrap'>
                            {/* @ts-ignore */}
                            <input onInput={e => form.setData('search', e.target.value)}
                                   value={form.data.search} className='input'
                                   placeholder={'搜索文章'}
                                   onKeyDown={e => {
                                       if (e.key === 'Enter') {
                                           onSearch()
                                       }
                                   }}
                                   type="search"/>
                            <button className='btn' onClick={onSearch}>
                                <span className={'iconfont icon-sousuo'}></span>
                            </button>
                        </div>
                    </div>

                    <Collapse activeKey={menuCollapseActive}
                              className={'menu-collapse'}
                              expandIconPosition={'end'}
                              accordion
                              onChange={(key) => setMenuCollapseActive(key)}
                              collapsible={'icon'}
                              items={menuCollapseItems}/>

                    <div className={'btn-wrap'}>
                        <MLink target={'_blank'} className='month-donate btn-primary'
                               href={layoutContext.props.donateMonthLink}>{t('我要月捐')}</MLink>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className="container">
                        <div className='header-wrap'>
                            <Link className='logo' href={route('/')}>
                                <ImageCrop src={layoutContext.props.header.logo} srcSet={{
                                    '1x': {
                                        height: 50,
                                    },
                                    '2x': {
                                        height: 100,
                                    },
                                    '1.5x': {
                                        height: 100,
                                    }
                                }}/>
                            </Link>
                            <div className='nav-wrap'>
                                <div className="nav">
                                    <div className='top'>
                                        <div className='search-wrap'>
                                            {/* @ts-ignore */}
                                            <input onInput={e => form.setData('search', e.target.value)}
                                                   value={form.data.search} className='input' placeholder={'搜索文章'}
                                                   onKeyDown={e => {
                                                       if (e.key === 'Enter') {
                                                           onSearch()
                                                       }
                                                   }}
                                                   type="search"/>
                                            <button className='btn' onClick={onSearch}>
                                                <span className={'iconfont icon-sousuo'}></span>
                                            </button>
                                        </div>
                                        <div className="nav-more">
                                            <Link className='nav-item' href={route('/Home/IndexEn/index')}>
                                                ENGLISH
                                            </Link>
                                            <TranslateBig5 className={'nav-item'}></TranslateBig5>
                                        </div>
                                    </div>
                                    <div className='nav-list'>
                                        {
                                            layoutContext.props.header.nav?.map((item, index) =>
                                                <div className={'nav-item'}
                                                     key={index}>
                                                    <Link
                                                        className={'link' + (item.title === activeNav[0] ? ' active' : '')}
                                                        href={item.link}>{t(item.title)}</Link>
                                                    {
                                                        item.children && <>
                                                            <div className="nav-popup">
                                                                {
                                                                    item.children?.map((child, index) =>
                                                                        <Link
                                                                            className={'link' + (child.title === activeNav[1] ? ' active' : '')}
                                                                            key={index}
                                                                            href={child.link}>{t(child.title)}</Link>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <MLink target={'_blank'} className='month-donate btn-primary'
                                       href={layoutContext.props.donateMonthLink}>{t('我要月捐')}</MLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
}
import {useContext, useEffect, useState} from "react";
import {route} from "@/route";
import {LayoutContext} from "@/Components/LayoutContext";
import {excludeObjectKeys} from "@/helper";
import {usePage} from "@inertiajs/react";

const handleUrl = (option: {
    src: string,
    width?: number,
    height?: number,
    requestUrl?: string,
    toJpg?: boolean,
}) => {
    if (!option.requestUrl || !option.src) {
        return option.src
    }

    if (!option.src && !option.src) {
        return option.src
    }

    const url = new URL(option.src, option.requestUrl)
    const host = (new URL(option.requestUrl)).host
    if (url.host === host) {
        // 本地图片
        return url.protocol + '//' + url.host + route('/ip/') + [option.width, option.height].join('x') + '/' + url.protocol + '//' + url.host + url.pathname
    }
    // cos图片
    if (option.width && option.height) {
        return option.src + `?imageMogr2/crop/${option.width}x${option.height}/gravity/center${option.toJpg ? '/format/jpg' : ''}`
    }
    return option.src + `?imageMogr2/thumbnail/${option.width || ''}x${option.height || ''}${option.toJpg ? '/format/jpg' : ''}`
}

export default function (props: {
    src: string,
    className?: string,
    alt?: string,
    width?: number,
    height?: number,
    notParent?:boolean,

    useDefaultThumb?: boolean,
    toJpg?: boolean,

    srcSet?: Record<string, {
        width?: number,
        height?: number,
    }>
}) {

    const pageProps = usePage<{
        requestUrl: string,
    }>().props

    const layoutContext = useContext(LayoutContext)

    let src = props.src
    if (!src && props.useDefaultThumb) {
        src = layoutContext.props.defaultThumb
    }

    const [imgSrc, setImgSrc] = useState(handleUrl({
        src,
        width: props.width,
        height: props.height,
        requestUrl: pageProps.requestUrl,
        toJpg: typeof props.toJpg === 'undefined' ? true : props.toJpg,
    }))
    const [imgSrcSets, setImgSrcSets] = useState([])


    useEffect(() => {
        let src = props.src
        if (!src && props.useDefaultThumb) {
            src = layoutContext.props.defaultThumb
        }

        setImgSrc(handleUrl({
            src: src,
            width: props.width,
            height: props.height,
            requestUrl: pageProps.requestUrl,
            toJpg: typeof props.toJpg === 'undefined' ? true : props.toJpg,
        }))

        if (props.srcSet) {
            // @ts-ignore
            setImgSrcSets(Object.entries(props.srcSet).map(([key, value]) => {
                return handleUrl({
                    src: src,
                    width: value.width,
                    height: value.height,
                    requestUrl: pageProps.requestUrl,
                    toJpg: typeof props.toJpg === 'undefined' ? true : props.toJpg,
                }) + ' ' + key
            }))
        }
    }, [props]);

    return (props.notParent ?
        <>
        <img {...excludeObjectKeys(props, [
            'src', 'srcSet', 'useDefaultThumb', 'width', 'height', 'toJpg'
        ])} src={imgSrc} srcSet={imgSrcSets.join(',')}/>
    </>:
    <div className="imgcrop-container">
        <img {...excludeObjectKeys(props, [
            'src', 'srcSet', 'useDefaultThumb', 'width', 'height', 'toJpg'
        ])} src={imgSrc} srcSet={imgSrcSets.join(',')}/>
    </div>)
}
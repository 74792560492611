import defaultStyles from './ReturnTop.module.scss'
import mobileStyles from './ReturnTop.mobile.module.scss'
import {useEffect, useState} from "react";
import {useDeviceDetector} from "@/Hooks/useDeviceDetector";

export default function () {
    const {styles} = useDeviceDetector({
        defaultStyles,
        mobileStyles,
    })
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        const listener = () => {
            setIsShow(window.scrollY > 200)
        }
        listener()
        window.addEventListener('scroll', listener)
        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, []);

    const onClick = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        })
    }

    return <>
        <a className={styles.returnTop + ' ' + (isShow ? '' : styles.hide)} onClick={onClick}>
            <span className={'iconfont icon-back-top1_fill'}></span>
        </a>
    </>
}
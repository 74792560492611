import {useContext, useEffect, useState} from "react";
import {usePage} from "@inertiajs/react";
import {LayoutContext} from "@/Components/LayoutContext";
import {GlobalEvent} from "@inertiajs/core/types/types";
import {route} from "@/route";

declare global {
    interface Window {
        wx: any
    }
}

export default function () {
    const {wxShareConfig, wxShareData} = usePage<{
        wxShareConfig: any,
        wxShareData: any
    }>().props

    if (!wxShareConfig) {
        return <></>
    }

    const [isReady, setIsReady] = useState(false)

    const layoutContext = useContext(LayoutContext)

    const share = async (wxShareData: any = {}) => {
        await new Promise(resolve => {
            setTimeout(resolve, 100)
        })
        const data = {
            title: wxShareData?.title || document.title,
            desc: wxShareData?.desc || document.querySelector('meta[name="description"]')?.getAttribute('content') || '',
            imgUrl: wxShareData?.imgUrl || (window.location.protocol + '//' + window.location.host + route('Public/images/wx_share_logo.jpg')),
            link: wxShareData?.link || window.location.href
        }

        window.wx.updateAppMessageShareData({
            title: data.title, // 分享标题
            desc: data.desc, // 分享描述
            link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: data.imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })

        window.wx.updateTimelineShareData({
            title: data.title, // 分享标题
            link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: data.imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })
    }

    useEffect(() => {
        if (!isReady) {
            const script = document.createElement('script')
            script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
            document.body.appendChild(script)
            script.onload = () => {
                window.wx.config(wxShareConfig)

                window.wx.ready(function () {
                    setIsReady(true)
                    share()
                });
            }
        } else {
            share()
        }

        const listener = (e: GlobalEvent<'navigate'>) => {
            share(e.detail.page.props.wxShareData)
        }

        document.addEventListener('inertia:success', listener)
        return () => {
            document.removeEventListener('inertia:success', listener)
        }

    }, [wxShareConfig]);

    return <></>
}